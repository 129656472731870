/**
 *
 * PatientHistoryCard
 *
 */
import React from 'react'

import { useMeasure } from 'react-use'
import { EuiTitle } from '@elastic/eui'
import { PatientStaticHistory } from '../PatientStaticHistory'
import { PatientHistoryTimeline } from './PatientHistoryTimeline'

export type PatientHistoryCardProps = {
  patientID: string
  selectDrug: (any) => void
  selectDiag: (any) => void
}

export const PatientHistoryCard = ({
  patientID,
  selectDrug,
  selectDiag,
}: PatientHistoryCardProps) => {
  const [cardRef, { width }] = useMeasure()

  const onClick = (data) => {
    const { item, group, what } = data
    if (item && what === 'item' && group === 'drug') {
      // @ts-ignore
      selectDrug(item)
    }
    if (item && what === 'item' && group === 'diag') {
      // @ts-ignore
      selectDiag(item)
    }
  }

  return (
    <>
      {/*// @ts-ignore*/}
      <div ref={cardRef}>
        <EuiTitle size="xs">
          <h2>Summary</h2>
        </EuiTitle>
        <PatientHistoryTimeline
          patientID={patientID}
          // @ts-ignore
          width={width}
          onClick={onClick}
        />
        <PatientStaticHistory patientID={patientID} />
      </div>
    </>
  )
}
