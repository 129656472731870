// tslint:disable
/**
 * Patient Demonstrator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosInstance, AxiosPromise } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequestArgs, RequiredError } from './base'

/**
 *
 * @export
 * @interface Graph
 */
export interface Graph {
  /**
   *
   * @type {GraphEnd}
   * @memberof Graph
   */
  end: GraphEnd
  /**
   *
   * @type {GraphEnd}
   * @memberof Graph
   */
  start: GraphEnd
}
/**
 *
 * @export
 * @interface GraphEnd
 */
export interface GraphEnd {
  /**
   *
   * @type {string}
   * @memberof GraphEnd
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof GraphEnd
   */
  lable?: string
  /**
   *
   * @type {string}
   * @memberof GraphEnd
   */
  type?: string
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   * Semi-colon separated file of the patient\'s diagnoses
   * @type {any}
   * @memberof InlineObject
   */
  diagnosis?: any
  /**
   * Semi-colon separated file of the patient\'s general information
   * @type {any}
   * @memberof InlineObject
   */
  _static?: any
  /**
   * Semi-colon separated file of the patient\'s treatments
   * @type {any}
   * @memberof InlineObject
   */
  treatment?: any
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {number}
   * @memberof InlineResponse200
   */
  diag?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  diag_curie?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse200
   */
  enrolid?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse200
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  long_descr?: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  short_descr?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse200
   */
  time?: number
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {number}
   * @memberof InlineResponse2001
   */
  enrolid?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse2001
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001
   */
  substancename?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse2001
   */
  time?: number
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2002
   */
  comorb?: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse2002
   */
  domain?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse2002
   */
  enrolid?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2002
   */
  feature?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse2002
   */
  feature_id?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2002
   */
  feature_pruned?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse2002
   */
  feature_value?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse2002
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse2002
   */
  shap_value?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse2002
   */
  shap_value_rank?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2002
   */
  subdomain?: string
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
  /**
   *
   * @type {number}
   * @memberof InlineResponse2003
   */
  age?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  coverage_of_prescriptions?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse2003
   */
  enrolid?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  hlthplnc?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse2003
   */
  hospdays?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse2003
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  mma?: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  plantypc?: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  regionc?: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse2003
   */
  sexc?: string
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
  /**
   *
   * @type {number}
   * @memberof InlineResponse2004
   */
  enrolid?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2004
   */
  feature?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse2004
   */
  feature_value?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse2004
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse2004
   */
  shap_value_rank?: number
  /**
   *
   * @type {number}
   * @memberof InlineResponse2004
   */
  shap_values?: number
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2005
   */
  diagnosis_filename?: string
  /**
   *
   * @type {number}
   * @memberof InlineResponse2005
   */
  job_status?: number
  /**
   *
   * @type {string}
   * @memberof InlineResponse2005
   */
  static_filename?: string
  /**
   *
   * @type {string}
   * @memberof InlineResponse2005
   */
  treatment_filename?: string
}

/**
 * EnrolsApi - axios parameter creator
 * @export
 */
export const EnrolsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get diags_samp by enrol ID
     * @summary Get diags_samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetDiagsSampByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetDiagsSampByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/diags_samp/{enrolid}`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get drugs_samp by enrol ID
     * @summary Get drugs_samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetDrugsSampByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetDrugsSampByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/drugs_samp/{enrolid}`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get all enrol IDs (patient IDs)
     * @summary Get all enrol IDs (patient IDs)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetEnrolids: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/enrols/enrolids`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * frontend_diags_history table
     * @summary Get diags_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendDiagsHistoryByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetFrontendDiagsHistoryByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/{enrolid}/diags_history`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * frontend_drugs_history table
     * @summary Get drugs_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendDrugsHistoryByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetFrontendDrugsHistoryByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/{enrolid}/drugs_history`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * frontend_risk_prediction table
     * @summary Get risk_prediction by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendRiskPredictionByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetFrontendRiskPredictionByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/{enrolid}/risk_prediction`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * frontend_shap_prediction table
     * @summary Get shap_prediction by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendShapPredictionByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetFrontendShapPredictionByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/{enrolid}/shap_prediction`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * frontend_static_history table
     * @summary Get static_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendStaticHistoryByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetFrontendStaticHistoryByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/{enrolid}/static_history`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetShapSampByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetShapSampByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/{enrolid}/shap_samp`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * frontend_diags_history table
     * @summary Get Static Samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetStaticSampByEnrolid: async (
      enrolid: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1EnrolsGetStaticSampByEnrolid.',
        )
      }
      const localVarPath = `/api/v1/enrols/{enrolid}/static_samp`.replace(
        `{${'enrolid'}}`,
        encodeURIComponent(String(enrolid)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * EnrolsApi - functional programming interface
 * @export
 */
export const EnrolsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get diags_samp by enrol ID
     * @summary Get diags_samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetDiagsSampByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<InlineResponse200>>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetDiagsSampByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get drugs_samp by enrol ID
     * @summary Get drugs_samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetDrugsSampByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<InlineResponse2001>>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetDrugsSampByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get all enrol IDs (patient IDs)
     * @summary Get all enrol IDs (patient IDs)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetEnrolids(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetEnrolids(options)
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * frontend_diags_history table
     * @summary Get diags_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetFrontendDiagsHistoryByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<InlineResponse200>>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetFrontendDiagsHistoryByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * frontend_drugs_history table
     * @summary Get drugs_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetFrontendDrugsHistoryByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<InlineResponse2001>>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetFrontendDrugsHistoryByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * frontend_risk_prediction table
     * @summary Get risk_prediction by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetFrontendRiskPredictionByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<InlineResponse2002>>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetFrontendRiskPredictionByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * frontend_shap_prediction table
     * @summary Get shap_prediction by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetFrontendShapPredictionByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<InlineResponse2002>>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetFrontendShapPredictionByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * frontend_static_history table
     * @summary Get static_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetFrontendStaticHistoryByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse2003>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetFrontendStaticHistoryByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetShapSampByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse2001>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetShapSampByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * frontend_diags_history table
     * @summary Get Static Samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1EnrolsGetStaticSampByEnrolid(
      enrolid: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse2004>
    > {
      const localVarAxiosArgs = await EnrolsApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1EnrolsGetStaticSampByEnrolid(
        enrolid,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * EnrolsApi - factory interface
 * @export
 */
export const EnrolsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     * Get diags_samp by enrol ID
     * @summary Get diags_samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetDiagsSampByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<Array<InlineResponse200>> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetDiagsSampByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get drugs_samp by enrol ID
     * @summary Get drugs_samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetDrugsSampByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<Array<InlineResponse2001>> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetDrugsSampByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get all enrol IDs (patient IDs)
     * @summary Get all enrol IDs (patient IDs)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetEnrolids(
      options?: any,
    ): AxiosPromise<Array<number>> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetEnrolids(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * frontend_diags_history table
     * @summary Get diags_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendDiagsHistoryByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<Array<InlineResponse200>> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetFrontendDiagsHistoryByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * frontend_drugs_history table
     * @summary Get drugs_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendDrugsHistoryByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<Array<InlineResponse2001>> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetFrontendDrugsHistoryByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * frontend_risk_prediction table
     * @summary Get risk_prediction by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendRiskPredictionByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<Array<InlineResponse2002>> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetFrontendRiskPredictionByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * frontend_shap_prediction table
     * @summary Get shap_prediction by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendShapPredictionByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<Array<InlineResponse2002>> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetFrontendShapPredictionByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * frontend_static_history table
     * @summary Get static_history by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetFrontendStaticHistoryByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<InlineResponse2003> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetFrontendStaticHistoryByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetShapSampByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<InlineResponse2001> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetShapSampByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * frontend_diags_history table
     * @summary Get Static Samp by enrol ID
     * @param {number} enrolid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1EnrolsGetStaticSampByEnrolid(
      enrolid: number,
      options?: any,
    ): AxiosPromise<InlineResponse2004> {
      return EnrolsApiFp(configuration)
        .patientDemonstratorApiWebApiV1EnrolsGetStaticSampByEnrolid(
          enrolid,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * EnrolsApi - object-oriented interface
 * @export
 * @class EnrolsApi
 * @extends {BaseAPI}
 */
export class EnrolsApi extends BaseAPI {
  /**
   * Get diags_samp by enrol ID
   * @summary Get diags_samp by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetDiagsSampByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetDiagsSampByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get drugs_samp by enrol ID
   * @summary Get drugs_samp by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetDrugsSampByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetDrugsSampByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get all enrol IDs (patient IDs)
   * @summary Get all enrol IDs (patient IDs)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetEnrolids(options?: any) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetEnrolids(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * frontend_diags_history table
   * @summary Get diags_history by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetFrontendDiagsHistoryByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetFrontendDiagsHistoryByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * frontend_drugs_history table
   * @summary Get drugs_history by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetFrontendDrugsHistoryByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetFrontendDrugsHistoryByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * frontend_risk_prediction table
   * @summary Get risk_prediction by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetFrontendRiskPredictionByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetFrontendRiskPredictionByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * frontend_shap_prediction table
   * @summary Get shap_prediction by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetFrontendShapPredictionByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetFrontendShapPredictionByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * frontend_static_history table
   * @summary Get static_history by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetFrontendStaticHistoryByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetFrontendStaticHistoryByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get Static Samp by enrol ID
   * @summary Get Static Samp by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetShapSampByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetShapSampByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * frontend_diags_history table
   * @summary Get Static Samp by enrol ID
   * @param {number} enrolid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EnrolsApi
   */
  public patientDemonstratorApiWebApiV1EnrolsGetStaticSampByEnrolid(
    enrolid: number,
    options?: any,
  ) {
    return EnrolsApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1EnrolsGetStaticSampByEnrolid(
        enrolid,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * GraphApi - axios parameter creator
 * @export
 */
export const GraphApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {string} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDiagnosisByFeature: async (
      feature: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      if (feature === null || feature === undefined) {
        throw new RequiredError(
          'feature',
          'Required parameter feature was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDiagnosisByFeature.',
        )
      }
      const localVarPath = `/api/v1/graph/shap/diagnosis/{feature}`.replace(
        `{${'feature'}}`,
        encodeURIComponent(String(feature)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get diagnosis features by diagnosis and subdomain
     * @summary Get diagnosis features by diagnosis and subdomain
     * @param {number} enrolid
     * @param {string} featurePruned TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain: async (
      enrolid: number,
      featurePruned: string,
      subdomain: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain.',
        )
      }
      // verify required parameter 'featurePruned' is not null or undefined
      if (featurePruned === null || featurePruned === undefined) {
        throw new RequiredError(
          'featurePruned',
          'Required parameter featurePruned was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain.',
        )
      }
      // verify required parameter 'subdomain' is not null or undefined
      if (subdomain === null || subdomain === undefined) {
        throw new RequiredError(
          'subdomain',
          'Required parameter subdomain was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain.',
        )
      }
      const localVarPath = `/api/v1/graph/shap/enrols/{enrolid}/DISEASE/{subdomain}/{feature_pruned}`
        .replace(`{${'enrolid'}}`, encodeURIComponent(String(enrolid)))
        .replace(
          `{${'feature_pruned'}}`,
          encodeURIComponent(String(featurePruned)),
        )
        .replace(`{${'subdomain'}}`, encodeURIComponent(String(subdomain)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get Diagnosis subdomain by diagnosis
     * @summary Get Diagnosis subdomain by diagnosis
     * @param {string} diagnosis TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain: async (
      diagnosis: string,
      subdomain: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'diagnosis' is not null or undefined
      if (diagnosis === null || diagnosis === undefined) {
        throw new RequiredError(
          'diagnosis',
          'Required parameter diagnosis was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain.',
        )
      }
      // verify required parameter 'subdomain' is not null or undefined
      if (subdomain === null || subdomain === undefined) {
        throw new RequiredError(
          'subdomain',
          'Required parameter subdomain was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain.',
        )
      }
      const localVarPath = `/api/v1/graph/diag/{diagnosis}/{subdomain}`
        .replace(`{${'diagnosis'}}`, encodeURIComponent(String(diagnosis)))
        .replace(`{${'subdomain'}}`, encodeURIComponent(String(subdomain)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get Diagnosis subdomain by diagnosis
     * @summary Get Diagnosis subdomain by diagnosis
     * @param {string} diagnosis TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDiagnosisSubdomainByDiagnosis: async (
      diagnosis: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'diagnosis' is not null or undefined
      if (diagnosis === null || diagnosis === undefined) {
        throw new RequiredError(
          'diagnosis',
          'Required parameter diagnosis was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDiagnosisSubdomainByDiagnosis.',
        )
      }
      const localVarPath = `/api/v1/graph/diag/{diagnosis}`.replace(
        `{${'diagnosis'}}`,
        encodeURIComponent(String(diagnosis)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get drug features by substance and subdomain
     * @summary Get drug features by substance and subdomain
     * @param {number} enrolid
     * @param {string} featurePruned TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain: async (
      enrolid: number,
      featurePruned: string,
      subdomain: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'enrolid' is not null or undefined
      if (enrolid === null || enrolid === undefined) {
        throw new RequiredError(
          'enrolid',
          'Required parameter enrolid was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain.',
        )
      }
      // verify required parameter 'featurePruned' is not null or undefined
      if (featurePruned === null || featurePruned === undefined) {
        throw new RequiredError(
          'featurePruned',
          'Required parameter featurePruned was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain.',
        )
      }
      // verify required parameter 'subdomain' is not null or undefined
      if (subdomain === null || subdomain === undefined) {
        throw new RequiredError(
          'subdomain',
          'Required parameter subdomain was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain.',
        )
      }
      const localVarPath = `/api/v1/graph/shap/enrols/{enrolid}/DRUG/{subdomain}/{feature_pruned}`
        .replace(`{${'enrolid'}}`, encodeURIComponent(String(enrolid)))
        .replace(
          `{${'feature_pruned'}}`,
          encodeURIComponent(String(featurePruned)),
        )
        .replace(`{${'subdomain'}}`, encodeURIComponent(String(subdomain)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get Drug subdomain by substancename
     * @summary Get Drug subdomain by substancename
     * @param {string} substancename TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain: async (
      substancename: string,
      subdomain: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'substancename' is not null or undefined
      if (substancename === null || substancename === undefined) {
        throw new RequiredError(
          'substancename',
          'Required parameter substancename was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain.',
        )
      }
      // verify required parameter 'subdomain' is not null or undefined
      if (subdomain === null || subdomain === undefined) {
        throw new RequiredError(
          'subdomain',
          'Required parameter subdomain was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain.',
        )
      }
      const localVarPath = `/api/v1/graph/drug/{substancename}/{subdomain}`
        .replace(
          `{${'substancename'}}`,
          encodeURIComponent(String(substancename)),
        )
        .replace(`{${'subdomain'}}`, encodeURIComponent(String(subdomain)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get Drug subdomain by substancename
     * @summary Get Drug subdomain by substancename
     * @param {string} substancename TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDrugSubdomainBySubstancename: async (
      substancename: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'substancename' is not null or undefined
      if (substancename === null || substancename === undefined) {
        throw new RequiredError(
          'substancename',
          'Required parameter substancename was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetDrugSubdomainBySubstancename.',
        )
      }
      const localVarPath = `/api/v1/graph/drug/{substancename}`.replace(
        `{${'substancename'}}`,
        encodeURIComponent(String(substancename)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {string} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetSubstancenameByFeature: async (
      feature: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'feature' is not null or undefined
      if (feature === null || feature === undefined) {
        throw new RequiredError(
          'feature',
          'Required parameter feature was null or undefined when calling patientDemonstratorApiWebApiV1GraphGetSubstancenameByFeature.',
        )
      }
      const localVarPath = `/api/v1/graph/shap/substancename/{feature}`.replace(
        `{${'feature'}}`,
        encodeURIComponent(String(feature)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GraphApi - functional programming interface
 * @export
 */
export const GraphApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {string} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1GraphGetDiagnosisByFeature(
      feature: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Graph>>
    > {
      const localVarAxiosArgs = await GraphApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1GraphGetDiagnosisByFeature(
        feature,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get diagnosis features by diagnosis and subdomain
     * @summary Get diagnosis features by diagnosis and subdomain
     * @param {number} enrolid
     * @param {string} featurePruned TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain(
      enrolid: number,
      featurePruned: string,
      subdomain: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Graph>>
    > {
      const localVarAxiosArgs = await GraphApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain(
        enrolid,
        featurePruned,
        subdomain,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get Diagnosis subdomain by diagnosis
     * @summary Get Diagnosis subdomain by diagnosis
     * @param {string} diagnosis TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain(
      diagnosis: string,
      subdomain: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Graph>>
    > {
      const localVarAxiosArgs = await GraphApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain(
        diagnosis,
        subdomain,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get Diagnosis subdomain by diagnosis
     * @summary Get Diagnosis subdomain by diagnosis
     * @param {string} diagnosis TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1GraphGetDiagnosisSubdomainByDiagnosis(
      diagnosis: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Graph>>
    > {
      const localVarAxiosArgs = await GraphApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1GraphGetDiagnosisSubdomainByDiagnosis(
        diagnosis,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get drug features by substance and subdomain
     * @summary Get drug features by substance and subdomain
     * @param {number} enrolid
     * @param {string} featurePruned TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain(
      enrolid: number,
      featurePruned: string,
      subdomain: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Graph>>
    > {
      const localVarAxiosArgs = await GraphApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain(
        enrolid,
        featurePruned,
        subdomain,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get Drug subdomain by substancename
     * @summary Get Drug subdomain by substancename
     * @param {string} substancename TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain(
      substancename: string,
      subdomain: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Graph>>
    > {
      const localVarAxiosArgs = await GraphApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain(
        substancename,
        subdomain,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get Drug subdomain by substancename
     * @summary Get Drug subdomain by substancename
     * @param {string} substancename TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1GraphGetDrugSubdomainBySubstancename(
      substancename: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Graph>>
    > {
      const localVarAxiosArgs = await GraphApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1GraphGetDrugSubdomainBySubstancename(
        substancename,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {string} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1GraphGetSubstancenameByFeature(
      feature: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Graph>>
    > {
      const localVarAxiosArgs = await GraphApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1GraphGetSubstancenameByFeature(
        feature,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * GraphApi - factory interface
 * @export
 */
export const GraphApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {string} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDiagnosisByFeature(
      feature: string,
      options?: any,
    ): AxiosPromise<Array<Graph>> {
      return GraphApiFp(configuration)
        .patientDemonstratorApiWebApiV1GraphGetDiagnosisByFeature(
          feature,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get diagnosis features by diagnosis and subdomain
     * @summary Get diagnosis features by diagnosis and subdomain
     * @param {number} enrolid
     * @param {string} featurePruned TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain(
      enrolid: number,
      featurePruned: string,
      subdomain: string,
      options?: any,
    ): AxiosPromise<Array<Graph>> {
      return GraphApiFp(configuration)
        .patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain(
          enrolid,
          featurePruned,
          subdomain,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get Diagnosis subdomain by diagnosis
     * @summary Get Diagnosis subdomain by diagnosis
     * @param {string} diagnosis TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain(
      diagnosis: string,
      subdomain: string,
      options?: any,
    ): AxiosPromise<Array<Graph>> {
      return GraphApiFp(configuration)
        .patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain(
          diagnosis,
          subdomain,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get Diagnosis subdomain by diagnosis
     * @summary Get Diagnosis subdomain by diagnosis
     * @param {string} diagnosis TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDiagnosisSubdomainByDiagnosis(
      diagnosis: string,
      options?: any,
    ): AxiosPromise<Array<Graph>> {
      return GraphApiFp(configuration)
        .patientDemonstratorApiWebApiV1GraphGetDiagnosisSubdomainByDiagnosis(
          diagnosis,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get drug features by substance and subdomain
     * @summary Get drug features by substance and subdomain
     * @param {number} enrolid
     * @param {string} featurePruned TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain(
      enrolid: number,
      featurePruned: string,
      subdomain: string,
      options?: any,
    ): AxiosPromise<Array<Graph>> {
      return GraphApiFp(configuration)
        .patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain(
          enrolid,
          featurePruned,
          subdomain,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get Drug subdomain by substancename
     * @summary Get Drug subdomain by substancename
     * @param {string} substancename TODO: namespace?
     * @param {string} subdomain TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain(
      substancename: string,
      subdomain: string,
      options?: any,
    ): AxiosPromise<Array<Graph>> {
      return GraphApiFp(configuration)
        .patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain(
          substancename,
          subdomain,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get Drug subdomain by substancename
     * @summary Get Drug subdomain by substancename
     * @param {string} substancename TODO: namespace?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetDrugSubdomainBySubstancename(
      substancename: string,
      options?: any,
    ): AxiosPromise<Array<Graph>> {
      return GraphApiFp(configuration)
        .patientDemonstratorApiWebApiV1GraphGetDrugSubdomainBySubstancename(
          substancename,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get Static Samp by enrol ID
     * @summary Get Static Samp by enrol ID
     * @param {string} feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1GraphGetSubstancenameByFeature(
      feature: string,
      options?: any,
    ): AxiosPromise<Array<Graph>> {
      return GraphApiFp(configuration)
        .patientDemonstratorApiWebApiV1GraphGetSubstancenameByFeature(
          feature,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * GraphApi - object-oriented interface
 * @export
 * @class GraphApi
 * @extends {BaseAPI}
 */
export class GraphApi extends BaseAPI {
  /**
   * Get Static Samp by enrol ID
   * @summary Get Static Samp by enrol ID
   * @param {string} feature
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public patientDemonstratorApiWebApiV1GraphGetDiagnosisByFeature(
    feature: string,
    options?: any,
  ) {
    return GraphApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1GraphGetDiagnosisByFeature(
        feature,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get diagnosis features by diagnosis and subdomain
   * @summary Get diagnosis features by diagnosis and subdomain
   * @param {number} enrolid
   * @param {string} featurePruned TODO: namespace?
   * @param {string} subdomain TODO: namespace?
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain(
    enrolid: number,
    featurePruned: string,
    subdomain: string,
    options?: any,
  ) {
    return GraphApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1GraphGetDiagnosisFeatureBySubdomain(
        enrolid,
        featurePruned,
        subdomain,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get Diagnosis subdomain by diagnosis
   * @summary Get Diagnosis subdomain by diagnosis
   * @param {string} diagnosis TODO: namespace?
   * @param {string} subdomain TODO: namespace?
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain(
    diagnosis: string,
    subdomain: string,
    options?: any,
  ) {
    return GraphApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1GraphGetDiagnosisFeaturePrunedBySubdomain(
        diagnosis,
        subdomain,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get Diagnosis subdomain by diagnosis
   * @summary Get Diagnosis subdomain by diagnosis
   * @param {string} diagnosis TODO: namespace?
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public patientDemonstratorApiWebApiV1GraphGetDiagnosisSubdomainByDiagnosis(
    diagnosis: string,
    options?: any,
  ) {
    return GraphApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1GraphGetDiagnosisSubdomainByDiagnosis(
        diagnosis,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get drug features by substance and subdomain
   * @summary Get drug features by substance and subdomain
   * @param {number} enrolid
   * @param {string} featurePruned TODO: namespace?
   * @param {string} subdomain TODO: namespace?
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain(
    enrolid: number,
    featurePruned: string,
    subdomain: string,
    options?: any,
  ) {
    return GraphApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1GraphGetDrugFeatureBySubdomain(
        enrolid,
        featurePruned,
        subdomain,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get Drug subdomain by substancename
   * @summary Get Drug subdomain by substancename
   * @param {string} substancename TODO: namespace?
   * @param {string} subdomain TODO: namespace?
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain(
    substancename: string,
    subdomain: string,
    options?: any,
  ) {
    return GraphApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1GraphGetDrugFeaturePrunedBySubdomain(
        substancename,
        subdomain,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get Drug subdomain by substancename
   * @summary Get Drug subdomain by substancename
   * @param {string} substancename TODO: namespace?
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public patientDemonstratorApiWebApiV1GraphGetDrugSubdomainBySubstancename(
    substancename: string,
    options?: any,
  ) {
    return GraphApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1GraphGetDrugSubdomainBySubstancename(
        substancename,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get Static Samp by enrol ID
   * @summary Get Static Samp by enrol ID
   * @param {string} feature
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GraphApi
   */
  public patientDemonstratorApiWebApiV1GraphGetSubstancenameByFeature(
    feature: string,
    options?: any,
  ) {
    return GraphApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1GraphGetSubstancenameByFeature(
        feature,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Returns a ZIP file of example data
     * @summary Returns the requested example patient data as a ZIP file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1PatientGetExampleData: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/patient/download/example_data.zip`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Select a properly formatted tabular file of patient data to upload
     * @summary Upload a file with patient data.
     * @param {any} [diagnosis] Semi-colon separated file of the patient\\\&#39;s diagnoses
     * @param {any} [_static] Semi-colon separated file of the patient\\\&#39;s general information
     * @param {any} [treatment] Semi-colon separated file of the patient\\\&#39;s treatments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1PatientWriteNewPatientData: async (
      diagnosis?: any,
      _static?: any,
      treatment?: any,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/patient/new_patient`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      if (diagnosis !== undefined) {
        localVarFormParams.append('diagnosis', diagnosis as any)
      }

      if (_static !== undefined) {
        localVarFormParams.append('static', _static as any)
      }

      if (treatment !== undefined) {
        localVarFormParams.append('treatment', treatment as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      //@ts-ignore
      delete localVarUrlObj.search
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Returns a ZIP file of example data
     * @summary Returns the requested example patient data as a ZIP file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1PatientGetExampleData(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs = await PatientApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1PatientGetExampleData(options)
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Select a properly formatted tabular file of patient data to upload
     * @summary Upload a file with patient data.
     * @param {any} [diagnosis] Semi-colon separated file of the patient\\\&#39;s diagnoses
     * @param {any} [_static] Semi-colon separated file of the patient\\\&#39;s general information
     * @param {any} [treatment] Semi-colon separated file of the patient\\\&#39;s treatments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientDemonstratorApiWebApiV1PatientWriteNewPatientData(
      diagnosis?: any,
      _static?: any,
      treatment?: any,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InlineResponse2005>
    > {
      const localVarAxiosArgs = await PatientApiAxiosParamCreator(
        configuration,
      ).patientDemonstratorApiWebApiV1PatientWriteNewPatientData(
        diagnosis,
        _static,
        treatment,
        options,
      )
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     * Returns a ZIP file of example data
     * @summary Returns the requested example patient data as a ZIP file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1PatientGetExampleData(
      options?: any,
    ): AxiosPromise<any> {
      return PatientApiFp(configuration)
        .patientDemonstratorApiWebApiV1PatientGetExampleData(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Select a properly formatted tabular file of patient data to upload
     * @summary Upload a file with patient data.
     * @param {any} [diagnosis] Semi-colon separated file of the patient\\\&#39;s diagnoses
     * @param {any} [_static] Semi-colon separated file of the patient\\\&#39;s general information
     * @param {any} [treatment] Semi-colon separated file of the patient\\\&#39;s treatments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientDemonstratorApiWebApiV1PatientWriteNewPatientData(
      diagnosis?: any,
      _static?: any,
      treatment?: any,
      options?: any,
    ): AxiosPromise<InlineResponse2005> {
      return PatientApiFp(configuration)
        .patientDemonstratorApiWebApiV1PatientWriteNewPatientData(
          diagnosis,
          _static,
          treatment,
          options,
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI {
  /**
   * Returns a ZIP file of example data
   * @summary Returns the requested example patient data as a ZIP file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public patientDemonstratorApiWebApiV1PatientGetExampleData(options?: any) {
    return PatientApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1PatientGetExampleData(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Select a properly formatted tabular file of patient data to upload
   * @summary Upload a file with patient data.
   * @param {any} [diagnosis] Semi-colon separated file of the patient\\\&#39;s diagnoses
   * @param {any} [_static] Semi-colon separated file of the patient\\\&#39;s general information
   * @param {any} [treatment] Semi-colon separated file of the patient\\\&#39;s treatments
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientApi
   */
  public patientDemonstratorApiWebApiV1PatientWriteNewPatientData(
    diagnosis?: any,
    _static?: any,
    treatment?: any,
    options?: any,
  ) {
    return PatientApiFp(this.configuration)
      .patientDemonstratorApiWebApiV1PatientWriteNewPatientData(
        diagnosis,
        _static,
        treatment,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
