import React, { useEffect, useState } from 'react'

import split from 'lodash/split'

import { useQuery } from 'react-query'
import {
  getGraphByDiag,
  getGraphByDrug,
  getGraphByShapFeature,
} from '../../api'

import FeatureGroupGraph from './FeatureGroupGraph'

const FeatureGraphContainer = ({
  patientID,
  selectedFeature,
  selectedDrug,
  selectedDiag,
}) => {
  const [graphData, setGraphData] = useState()

  const [node, setNode] = useState()
  const [nodeType, setNodeType] = useState()

  const [domainSubdomain, feature_pruned] = split(selectedFeature, '.x.', 2)
  const [domain, subdomain] = split(domainSubdomain, '.', 2)

  const { data: shapFeatureResponse } = useQuery(
    feature_pruned && [
      `/graph/shap/enrols/${patientID}/${domain}/${subdomain}/${feature_pruned}`,
      domain,
      subdomain,
      feature_pruned,
      patientID,
    ],
    getGraphByShapFeature,
  )
  const shapFeatureData = shapFeatureResponse?.data

  useEffect(() => {
    if (shapFeatureData) {
      setGraphData(shapFeatureData)
      setNode('')
      setNodeType('')
      if (domain === 'DISEASE') setNodeType('diag')
      else if (domain === 'DRUG') setNodeType('drug')
    }
  }, [domain, shapFeatureData])

  /* Get graph  */
  const diag = selectedDiag
  const drug = selectedDrug

  const { data: diagResponse } = useQuery(
    diag && [`graph/diag/${diag}`, diag],
    getGraphByDiag,
  )
  const diagData = diagResponse?.data
  useEffect(() => {
    if (diagData) {
      setNode(diag)
      setNodeType('diag')
      setGraphData(diagData)
    }
  }, [diag, diagData])

  const { data: drugResponse } = useQuery(
    drug && [`graph/drug/${drug}`, drug],
    getGraphByDrug,
  )
  const drugData = drugResponse?.data
  useEffect(() => {
    setNode('')
    setNodeType('')
    if (drugData) {
      setNode(drug)
      setNodeType('drug')
      setGraphData(drugData)
    }
  }, [drug, drugData])

  return (
    <FeatureGroupGraph
      data={graphData}
      node={node}
      nodeType={nodeType}
      patientID={patientID}
    />
  )
}

export default FeatureGraphContainer
