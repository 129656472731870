import React, { useEffect, useState } from 'react'
import { Radio } from 'antd'

import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { usePrevious } from 'react-use'

import styled from 'styled-components'
import chroma from 'chroma-js'
import Graphin from '@antv/graphin'

import { Toolbar } from '@antv/graphin-components'
import '@antv/graphin/dist/index.css'

import '@antv/graphin-components/dist/index.css'
import { useQuery } from 'react-query'
import { expandGraphByNode } from '../../api'

export const GraphBox = styled.div`
  border: 1px solid #d3d3d3;
  position: relative;
  margin-top: 5px;
`

const colors = chroma.scale('GnBu').colors(20)
const getNodeStyle = (type, name) => {
  if (type === 'DRUG' || type === 'DISEASE') {
    return {
      shape: 'HexagonNode',
      style: {
        primaryColor: colors.pop(),
      },
    }
  } else {
    const nameSplitted = name.split('.')
    if (nameSplitted[0] === 'DRUG' || nameSplitted[0] === 'DISEASE') {
      return {
        shape: 'RectNode',
        style: {
          primaryColor: colors.pop(),
        },
      }
    }
    return {
      style: {
        primaryColor: colors.pop(),
      },
    }
  }
}

const transform = (data) => {
  let returnNodes = []
  let returnEdges = []
  data.forEach((edge) => {
    const sourceStyle = getNodeStyle(edge.start.type, edge.start.label)
    const sourceNode = {
      id: String(edge.start.id),
      label: String(edge.start.label),
      type: edge.start.type,
      subdomain: edge.start.subdomain,
      diag: edge.start.diag,
      drug: edge.start.drug,
      ...sourceStyle,
    }
    const targetStyle = getNodeStyle(edge.end.type, edge.end.label)
    const targetNode = {
      id: String(edge.end.id),
      label: String(edge.end.label),
      type: edge.end.type,
      subdomain: edge.end.subdomain,
      diag: edge.end.diag,
      drug: edge.end.drug,
      ...targetStyle,
    }
    returnNodes.push(
      { ...sourceNode, data: sourceNode },
      { ...targetNode, data: targetNode },
    )
    returnEdges.push({
      data: edge,
      source: String(edge.start.id),
      target: String(edge.end.id),
    })
  })
  return {
    nodes: returnNodes,
    edges: returnEdges,
  }
}

const FeatureGroupGraph = ({ data, node, nodeType, patientID }) => {
  const [graphData, setGraphData] = useState()
  const [layoutName, setLayoutName] = useState('concentric')
  const [clickedNode, setClickedNode] = useState()

  const { data: nodeResponse } = useQuery(
    // clickedNode.type === 'Drug'
    clickedNode && [
      `graph/${nodeType}/${node}/${clickedNode?.nodeData?.label}`,
      node,
      nodeType,
      clickedNode,
    ],
    expandGraphByNode,
  )
  const nodeData = nodeResponse?.data

  useEffect(() => {
    if (!isNil(nodeData) && !isEmpty(nodeData)) {
      setClickedNode(null)
      const transformedSubdomainData = transform(nodeData)
      setGraphData((prevState) => ({
        edges: [...prevState.edges, ...transformedSubdomainData.edges],
        nodes: [...prevState.nodes, ...transformedSubdomainData.nodes],
      }))
    }
  }, [nodeData])

  useEffect(() => {
    if (!isNil(data) && !isEmpty(data)) {
      setClickedNode(null)
      setGraphData({ nodes: {}, edges: {} })
      setGraphData(transform(data))
    }
  }, [data])

  /* Layout Switcher */
  const onChange = (event) => {
    const newLayout = event.target.value
    setLayoutName(newLayout)
  }

  /* Toolbar */
  const renderToolbar = (renderProps, _state) => {
    const { toolbarCfg } = renderProps
    let newToolbarCfg = []
    toolbarCfg.forEach((cfg) => {
      if (cfg.id === 'fullscreen')
        newToolbarCfg.push({ ...cfg, name: 'Fullscreen' })
      if (cfg.id === 'zoomIn') newToolbarCfg.push({ ...cfg, name: 'Zoom In' })
      if (cfg.id === 'zoomOut') newToolbarCfg.push({ ...cfg, name: 'Zoom Out' })
      if (cfg.id === 'undo') newToolbarCfg.push({ ...cfg, name: 'Undo' })
      if (cfg.id === 'redo') newToolbarCfg.push({ ...cfg, name: 'Redo' })
    })
    return [...newToolbarCfg]
  }

  /* ContextMenu
  const contextMenuOptions = [
    {
      key: 'expand',
      title: 'Expand',
      iconType: <AiOutlineDeploymentUnit />,
      visible: true,
      onClick: (e) => {
        console.log(e)
      },
    },
  ]
  */

  /* Event handling */
  const graphRef = React.createRef(null)

  useEffect(() => {
    const { graph } = graphRef.current
    const onNodeClick = (e) => {
      const nodeData = e.item.get('model')
      const edgeData = e.item.get('edges')
      // how to get a source of the node
      const sourceEdges = edgeData.map((edge) => edge._cfg.model.data.start)
      setClickedNode({ nodeData, edgeData: sourceEdges })
    }
    graph.on('node:dblclick', onNodeClick)
    return () => {
      graph.off('node:dblclick', onNodeClick)
    }
  }, [graphRef])

  /* Reset the graph for a new patient */
  const prevPatientID = usePrevious(patientID)
  useEffect(() => {
    // const { graph } = graphRef.current
    if (patientID !== prevPatientID) {
      // TODO: test how clear is working
      // graph.clear()
      setGraphData({ nodes: [], edges: [] })
    }
  }, [graphRef, patientID, prevPatientID])

  return (
    <>
      Graph Layouts:
      <br />
      <Radio.Group
        name="radiogroup"
        defaultValue={layoutName}
        onChange={onChange}
      >
        <Radio value="concentric">Concentric</Radio>
        <Radio value="force">Force-Directed</Radio>
        <Radio value="radial">Radial</Radio>
        <Radio value="circle">Circle</Radio>
        <Radio value="grid">Grid</Radio>
        <Radio value="dagre">Hierarchy</Radio>
      </Radio.Group>
      <GraphBox>
        <Graphin data={graphData} layout={{ name: layoutName }} ref={graphRef}>
          <Toolbar
            render={renderToolbar}
            style={{ position: 'absolute', bottom: 28, left: 28 }}
          />
        </Graphin>
      </GraphBox>
    </>
  )
}

export default FeatureGroupGraph
