import styled from 'styled-components'
import { Layout } from 'antd'
export { Footer } from './Footer'

export const { Content } = Layout

const LayoutGrid = styled(Layout)`
  min-height: 100vh;
  background: rgba(232, 238, 248, 0.4);

  /*
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr auto;
  gap: 1px 1px;
  grid-template-areas:
    'header header header header'
    'content content content content'
    'footer footer footer footer';

  header {
    grid-area: header;
  }

  main {
    grid-area: content;
  }

  footer {
    grid-area: footer;
  }
  */
`

export default LayoutGrid
