import React, { FC } from 'react'

import Timeline from 'react-visjs-timeline'
import './group.css'

import moment from 'moment'

import { useQuery } from 'react-query'
import {
  getDiagnosisHistoryByPatientID,
  getDrugsHistoryByPatientID,
} from '../../api'

export type PatientHistoryTimelineProps = {
  patientID: any
  width: any
  onClick: (any) => void
}

export const PatientHistoryTimeline: FC<PatientHistoryTimelineProps> = ({
  patientID,
  width,
  onClick,
}) => {
  const { data: diagsHistory } = useQuery(
    patientID && [`patient/${patientID}/diags-history`, patientID],
    getDiagnosisHistoryByPatientID,
  )

  const { data: drugsHistory } = useQuery(
    patientID && [`patient/${patientID}/drugs-history`, patientID],
    getDrugsHistoryByPatientID,
  )

  let items: any = []
  if (diagsHistory) {
    diagsHistory.forEach((diagnosis) => {
      items.push({
        id: diagnosis.diag,
        content: diagnosis.short_descr,
        start: moment(new Date(+diagnosis.time, 0, 1)),
        group: 'diag',
        className: 'diag',
      })
    })
  }

  if (drugsHistory) {
    drugsHistory.forEach((drug) => {
      items.push({
        id: drug.substancename,
        content: drug.substancename,
        start: moment(new Date(+drug.time, 0, 1)),
        group: 'drug',
        className: 'drug',
      })
    })
  }

  // options
  const startDate = new Date(-1, 12, 31)
  const options = {
    height: '450px',
    width,
    start: moment(startDate).subtract(13, 'years'),
    end: moment(startDate),
    min: moment(startDate).subtract(13, 'years'),
    max: moment(startDate),
    zoomMin: 1000 * 60 * 60 * 24 * 31 * 12 * 7, // 6m in milliseconds
    zoomMax: 1000 * 60 * 60 * 24 * 31 * 12 * 15, // 15m in milliseconds
  }

  // create groups to highlight groupUpdate
  const groups = [
    {
      id: 'drug',
      content: 'Drugs',
    },
    {
      id: 'diag',
      content: 'Diagnoses',
    },
  ]

  /*const onClick = (data) => {
    const { item, group, what } = data
    if (item && what === 'item' && group === 'drug') {
      // @ts-ignore
      selectDrug(item)
    }
    if (item && what === 'item' && group === 'diag') {
      // @ts-ignore
      selectDiag(item)
    }
  }*/

  return (
    <Timeline
      options={options}
      groups={groups}
      items={items}
      clickHandler={onClick}
    />
  )
}
