/**
 *
 * UploadComponent
 *
 */
import React from 'react'

import { Button, Col, message, Row, Typography, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { Configuration, PatientApi } from '../../api/openapi'

const { Text } = Typography

export type UploadComponentProps = {}
export type UploadComponentState = {
  diagFile: any[]
  drugFile: any[]
  staticFile: any[]
  uploading: boolean
}

class UploadComponent extends React.Component<
  UploadComponentProps,
  UploadComponentState
> {
  state = {
    diagFile: [],
    drugFile: [],
    staticFile: [],
    uploading: false,
    messageText: '',
  }

  handleUpload = () => {
    const config = new Configuration()
    const patientController = new PatientApi(config)
    const { diagFile, drugFile, staticFile } = this.state

    this.setState({
      uploading: true,
    })

    console.log(diagFile, drugFile, staticFile)

    patientController
      .patientDemonstratorApiWebApiV1PatientWriteNewPatientData(
        diagFile[0],
        staticFile[0],
        drugFile[0],
      )
      .then(() => {
        this.setState({
          diagFile: [],
          drugFile: [],
          staticFile: [],
          uploading: false,
          // @ts-ignore
          messageText: (
            <>
              After clicking "Start Upload" the selected data will be uploaded
              <br />
              and processed. This will take several minutes, please do NOT
              <br /> interact with or refresh the site. Afterwards you need to
              navigate
              <br /> to main page of the patient demonstrator dashboards.
            </>
          ),
        })
        message.success('Upload successfully')
      })
      .catch(() => {
        this.setState({
          uploading: false,
          // @ts-ignore
          messageText: 'Upload failed',
        })
        message.error('Upload failed')
      })
  }

  render() {
    const {
      uploading,
      diagFile,
      drugFile,
      staticFile,
      messageText,
    } = this.state
    const diagProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.diagFile.indexOf(file)
          const newFileList = state.diagFile.slice()
          newFileList.splice(index, 1)
          return {
            diagFile: newFileList,
          }
        })
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          diagFile: [...state.diagFile, file],
        }))
        return false
      },
      diagFile,
    }
    const drugProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.drugFile.indexOf(file)
          const newFileList = state.drugFile.slice()
          newFileList.splice(index, 1)
          return {
            drugFile: newFileList,
          }
        })
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          drugFile: [...state.drugFile, file],
        }))
        return false
      },
      drugFile,
    }
    const staticProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.staticFile.indexOf(file)
          const newFileList = state.staticFile.slice()
          newFileList.splice(index, 1)
          return {
            staticFile: newFileList,
          }
        })
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          staticFile: [...state.staticFile, file],
        }))
        return false
      },
      staticFile,
    }

    return (
      <>
        <Row>
          <Col>
            <Upload {...drugProps} className="mx-1">
              <Button icon={<UploadOutlined />}>Select Treatment File</Button>
            </Upload>
          </Col>
          <Col>
            <Upload {...diagProps} className="mx-1">
              <Button icon={<UploadOutlined />}>Select Diagnosis File</Button>
            </Upload>
          </Col>
          <Col>
            <Upload {...staticProps} className="mx-1">
              <Button icon={<UploadOutlined />}>Select Static File</Button>
            </Upload>
          </Col>
        </Row>
        <Row>
          <Button
            type="primary"
            onClick={this.handleUpload}
            disabled={
              diagFile.length === 0 ||
              drugFile.length === 0 ||
              staticFile.length === 0
            }
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Start Upload'}
          </Button>
        </Row>
        {messageText && (
          <Row className="mt-3">
            <Typography>
              <Text type="secondary">{messageText}</Text>
            </Typography>
          </Row>
        )}
      </>
    )
  }
}

export default UploadComponent
