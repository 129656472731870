import React, { FC, useContext } from 'react'
import { EuiAccordion, EuiSplitPanel, EuiText } from '@elastic/eui'
import { SHAPChart } from '../../SHAPChart'
import { PatientContext } from '../../../App'

export type SHAPChartCardProps = {
  patientID?: string
  setSelectedFeature?: (any) => void
}

export const SHAPChartCard: FC<SHAPChartCardProps> = (
  props,
  // {  patientID, setSelectedFeature, }
) => {
  // @ts-ignore
  const { patient, feature } = useContext(PatientContext)
  const { patientID } = patient
  const { setSelectedFeature } = feature
  return (
    <EuiSplitPanel.Outer grow className="shap" style={{ minHeight: '530px' }}>
      <EuiSplitPanel.Inner>
        <EuiAccordion
          id="accordion1"
          buttonContent={
            <b>
              #3 <br /> Feature Importance: Top-20 Risk Factors
            </b>
          }
        >
          <EuiText>
            <ul>
              <li>
                <p>Choose disease of interest by selecting panel 1 to 6. </p>
              </li>
              <li>
                <p>
                  The direction of the bars show the patient specific importance
                  of features estimated via Shapley Additive Explanations
                  (SHAP): Left indicates that the feature attributes to risk
                  reduction and right (positive) risk increase.
                </p>
              </li>
              <li>
                <p>
                  The bar heights reflect the importance: The higher the bar,
                  the higher the contribution to the acutal predicted risk.
                </p>
              </li>
              <li>
                <p>
                  Colors indicate the feature origin (demographic, disease,
                  diagnosis)
                </p>
              </li>
              <li>
                <p>
                  Click on a diagnosis or drug to access the associated
                  biomedical background knowledge graph.
                  <span style={{ color: '#0070C0' }}>*</span>
                </p>
              </li>
            </ul>
            <p>
              <span style={{ color: '#0070C0' }}>*</span>In some cases
              (specifically with simulated data) it can happen that no
              biomedical background knowledge is available.
            </p>
          </EuiText>
        </EuiAccordion>
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner>
        <SHAPChart
          patientID={patientID}
          setSelectedFeature={setSelectedFeature}
        />
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  )
}
