/**
 *
 * DashboardPage
 *
 */
import React from 'react'

import LayoutGrid, { Content, Footer } from '../../components/layout'
import { Header } from '../../components/layout/Header'

import Dashboard from '../../components/Dashboard'

export type DashboardPageProps = {}

export const DashboardPage = (props: DashboardPageProps) => {
  return (
    <LayoutGrid>
      <Header />
      <Content>
        <Dashboard />
      </Content>
      <Footer />
    </LayoutGrid>
  )
}
