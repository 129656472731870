import React, { FC } from 'react'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSectionItem,
} from '@elastic/eui'

import axios from 'axios'

import { useRouterLinkProps } from '../../hooks'

export type HeaderProps = {}

export const Header: FC<HeaderProps> = () => {
  const [hrefMain, onClickMain] = useRouterLinkProps('/')
  const [hrefUpload, onClickUpload] = useRouterLinkProps('/upload')
  const [hrefHelp, onClickHelp] = useRouterLinkProps('/help')

  const handleDownloadClick = () => {
    axios({
      method: 'get',
      url: `${process.env.PUBLIC_URL}/api/v1/patient/download/example_data.zip`,
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/octet-stream' }),
        )
        link.download = 'example_data.zip'

        document.body.appendChild(link)

        link.click()
        setTimeout(function () {
          // @ts-ignore
          window.URL.revokeObjectURL(link)
        }, 200)
      })
      .catch((error) => {})
  }

  return (
    <EuiHeader>
      <EuiHeaderSectionItem border="right">
        <EuiHeaderLogo
          // @ts-ignore
          href={hrefMain}
          // @ts-ignore
          onClick={onClickMain}
          iconType="heartbeatApp"
        >
          Patient Demonstrator
        </EuiHeaderLogo>
        <EuiButton
          className="ml-3"
          color="secondary"
          title="Upload Patient Data"
          aria-label="Upload Patient Data"
          // @ts-ignore
          href={hrefUpload}
          // @ts-ignore
          onClick={onClickUpload}
        >
          Upload Data
        </EuiButton>
      </EuiHeaderSectionItem>
      <EuiHeaderSectionItem>
        <EuiButton
          className="mr-1"
          color="secondary"
          title="Help Page"
          aria-label="Help Page"
          // @ts-ignore
          href={hrefHelp}
          // @ts-ignore
          onClick={onClickHelp}
        >
          Help
        </EuiButton>
        <EuiButtonEmpty
          type="button"
          title="Download Scheme and Examples"
          aria-label="Download Data: Scheme and Examples"
          onClick={handleDownloadClick}
        >
          Download Data
        </EuiButtonEmpty>
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
