import React from 'react'

import { Select, Space } from 'antd'

import { useQuery } from 'react-query'
import { getPatients } from '../../api'

const { Option } = Select

const PatientSelector = ({ setPatientID }) => {
  const { data } = useQuery('patients', getPatients)

  let options
  if (data) {
    options = data.map((option) => (
      <Option key={option} value={option}>
        {option}
      </Option>
    ))
  }

  const onChange = (value) => {
    setPatientID(value)
  }

  return (
    <Space>
      Select
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Patient"
        optionFilterProp="children"
        onChange={onChange}
      >
        {options}
      </Select>
    </Space>
  )
}

export default PatientSelector
