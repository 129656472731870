import React, { FC, useEffect, useState } from 'react'

import { useMeasure } from 'react-use'
import { useQuery } from 'react-query'

import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

import { EuiTabbedContent, EuiTitle } from '@elastic/eui'

import { EuiSHAPChart } from './EuiSHAPChart'
import { getSHAPValuesDataByPatientID } from '../../api'

export type SHAPChartProps = {
  patientID: any
  setSelectedFeature: any
}

export const SHAPChart: FC<SHAPChartProps> = ({
  patientID,
  setSelectedFeature,
}) => {
  const [plotData, setPlotData] = useState([])
  const [cardRef, { width }] = useMeasure()

  const { data } = useQuery(
    patientID && [`patient/${patientID}/shap-values`, patientID],
    getSHAPValuesDataByPatientID,
  )

  /**
   * Fetch chart data
   */
  useEffect(() => {
    if (data) {
      const groupedData = groupBy(
        sortBy(data, (v) => v.shap_value_rank),
        'comorb',
      )
      // @ts-ignore
      setPlotData(groupedData)
    }
  }, [data])

  const plots = Object.entries(plotData).map((entries) => {
    const [name, singlePlotData] = entries

    /*
     There is a bug in the Elastic Charts library: in the
     XYChart negative values are not recognized as elements, so
     one can not click on them. As a workaround, I've created a
     ranged chart (which makes absolutely no sense) with max
     values for minimal value and min values for maximal value.
    */

    /*
    // @ts-ignore
    const fixedData = singlePlotData.map((rowValue) => {
      // TODO: here one can specify group of the data
      let g
      if (rowValue.domain === 'DRUG') g = 'Drug'
      return { ...rowValue, g }
      */

    return (
      <>
        <EuiTitle size="s">
          <h3>{name}</h3>
        </EuiTitle>
        <EuiSHAPChart
          plotData={singlePlotData}
          // plotData={fixedData}
          width={width}
          onClick={setSelectedFeature}
        />
      </>
    )
  })

  const tabs = [
    {
      id: 'id--1',
      name: '1',
      content: plots[0] || 'Loading...',
    },
    {
      id: 'id--2',
      name: '2',
      content: plots[1] || 'Loading...',
    },
    {
      id: 'id--3',
      name: '3',
      content: plots[2] || 'Loading...',
    },
    {
      id: 'id--4',
      name: '4',
      content: plots[3] || 'Loading...',
    },
    {
      id: 'id--5',
      name: '5',
      content: plots[4] || 'Loading...',
    },
    {
      id: 'id--6',
      name: '6',
      content: plots[5] || 'Loading...',
    },
  ]

  return (
    <>
      {/*// @ts-ignore*/}
      <div ref={cardRef}>
        <EuiTabbedContent
          tabs={tabs}
          initialSelectedTab={tabs[0]}
          autoFocus="selected"
        />
      </div>
    </>
  )
}
