import React, { FC } from 'react'
import {
  Axis,
  AxisStyle,
  BarSeries,
  Chart,
  Position,
  RecursivePartial,
  ScaleType,
  Settings,
} from '@elastic/charts'

import numeral from 'numeral'

export type EuiSHAPChartProps = {
  plotData: any[]
  width: number
  onClick: () => any
}

const customStyle: RecursivePartial<AxisStyle> = {
  tickLabel: {},
}

export const EuiSHAPChart: FC<EuiSHAPChartProps> = ({
  plotData,
  width,
  onClick,
}) => {
  const onElementListeners = {
    onProjectionClick: (value) => {
      const valueObject = plotData.find(
        (plotValue) => plotValue.feature_pruned === value.x,
      )
      // @ts-ignore
      onClick(valueObject.feature)
    },
    onElementClick: (element) => {
      // destructuring array of array
      const [[value]] = element
      const valueObject = plotData.find(
        (plotValue) => plotValue.feature_pruned === value.x,
      )
      // @ts-ignore
      onClick(valueObject.feature)
    },
  }
  return (
    <Chart className="shap-chart" size={{ height: 450, width }}>
      <Settings
        rotation={90}
        tooltip={{
          headerFormatter: (tooltipValue) => {
            const feature_pruned = tooltipValue.value
            const valueObject = plotData.find(
              (plotValue) => plotValue.feature_pruned === feature_pruned,
            )
            const { domain, subdomain } = valueObject
            const subdomainPruned = subdomain.split('.')[1]
            if (subdomainPruned)
              return `${domain} > ${subdomainPruned} > ${feature_pruned}`
            return `${domain} > ${feature_pruned}`
          },
        }}
        showLegend={false}
        {...onElementListeners}
      />
      <Axis id="feature" position={Position.Left} style={customStyle} />
      <Axis
        id="risk-score"
        title="risk score (SHAP value)"
        labelFormat={(d) => numeral(d).format('0.00')}
        tickFormat={(d) => numeral(d).format('0.00000')}
        position={Position.Bottom}
        domain={{ fit: true }}
      />
      <BarSeries
        id="risk score (SHAP value)"
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Sqrt}
        xAccessor={'feature_pruned'}
        yAccessors={['shap_value']}
        data={plotData}
        splitSeriesAccessors={['domain']}
        stackAccessors={['feature']}
      />
    </Chart>
  )
}
