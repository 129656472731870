import React, { FC } from 'react'
import { Link } from 'react-router-dom'

export interface Props {}

export const Footer: FC<Props> = (props) => {
  const currentYear = new Date().getFullYear()
  return (
    <footer className="footer container-fluid pt-2 border-top shadow-sm bg-light">
      <div className="row row-cols-auto ml-1">
        <div className="col text-center mt-3">
          <a href="https://www.scai.fraunhofer.de">
            <img
              className="mb-2"
              src={`${process.env.PUBLIC_URL}/static/brand/fraunhofer-logo.gif`}
              alt=""
              height="50"
            />
            <small className="d-block mb-3 text-muted">
              &copy; {currentYear} Fraunhofer-Gesellschaft
            </small>
          </a>
        </div>
        <div className="col-3 text-center mt-3">
          <h3 className="h5">Contact</h3>
          <ul className="list-unstyled text-small">
            <li>
              <a
                className="text-muted"
                href="https://www.scai.fraunhofer.de/en/contact-fraunhofer-scai.html"
              >
                Contact
              </a>
            </li>
            {/*
            <li>
              <Link className="text-muted" to="/support">
                Support
              </Link>
            </li>
            */}
          </ul>
        </div>
        <div className="col-3 text-center mt-3">
          <h3 className="h5">About</h3>
          <ul className="list-unstyled text-small">
            <li>
              <Link className="text-muted" to="/imprint">
                Imprint
              </Link>
            </li>
            <li>
              <Link className="text-muted" to="/data-protection">
                Data Protection
              </Link>
            </li>
            <li>
              <Link className="text-muted" to="/terms-and-conditions">
                Terms And Conditions
              </Link>
            </li>
            <li>
              <Link className="text-muted" to="/publishing-notes">
                Publishing Notes
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
