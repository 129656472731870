import React, { useContext } from 'react'

import styled from 'styled-components'
import PatientSelector from './PatientSelector'

import { PatientHistoryCard } from '../PatientHistoryCard'
import RiskProbChart from './RiskProbChart'
import FeatureGraphContainer from './FeatureGraphContainer'

import {
  EuiAccordion,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSplitPanel,
  EuiText,
} from '@elastic/eui'

import { PatientContext } from '../../App'
import { WelcomeMessage } from '../../pages/DashboardPage/WelcomeMessage'
import { SHAPChartCard } from './card/SHAPChartCard'

const DashboardStyle = styled.div`
  min-height: 100vh;
  margin-right: 50px;
  margin-left: 50px;
`

const GridDashboard = styled.div`
  display: grid;
  gap: 20px 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-template-areas:
    'patient-selector .'
    'shap shap'
    'riskprob riskprob'
    'graph graph'
    'patient-history patient-history'
    'scaiview scaiview';

  .patient-selector {
    grid-area: patient-selector;
  }

  .shap {
    grid-area: shap;
    min-height: 450px;
  }

  .riskprob {
    grid-area: riskprob;
    min-height: 450px;
  }

  .graph {
    grid-area: graph;
  }

  .patient-history {
    grid-area: patient-history;
  }

  .scaiview {
    grid-area: scaiview;
  }

  @media (min-width: 968px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      'patient-selector patient-selector . .'
      'shap shap riskprob riskprob'
      'graph graph patient-history patient-history'
      '. . scaiview scaiview';
  }
`

const Dashboard = () => {
  const { patient, feature, drug, diag } = useContext(PatientContext)
  const { patientID, setPatientID } = patient
  const { selectedFeature, setSelectedFeature } = feature
  const { selectedDrug, selectDrug } = drug
  const { selectedDiag, selectDiag } = diag

  return (
    <DashboardStyle>
      <EuiFlexGroup direction="column" className="patient-selector mt-3">
        <EuiFlexItem>
          <PatientSelector setPatientID={setPatientID} />
        </EuiFlexItem>
        <EuiFlexItem>
          {patientID ? (
            <GridDashboard className="grid-container">
              <SHAPChartCard
                patientID={patientID}
                setSelectedFeature={setSelectedFeature}
              />
              <EuiSplitPanel.Outer
                className="riskprob"
                style={{ minHeight: '530px' }}
              >
                <EuiSplitPanel.Inner>
                  <EuiAccordion
                    id="accordion1"
                    buttonContent={
                      <b>
                        #2 <br /> Predicted time dependent risks for six
                        different diseases
                      </b>
                    }
                  >
                    <EuiText>
                      <p>
                        Hover your mouse cursor across for details. For more
                        interactions, like zooming, use the buttons appearing in
                        the top right corner of this dashboard.
                      </p>
                    </EuiText>
                  </EuiAccordion>
                </EuiSplitPanel.Inner>
                <EuiSplitPanel.Inner>
                  <RiskProbChart patientID={patientID} />
                </EuiSplitPanel.Inner>
              </EuiSplitPanel.Outer>
              <EuiSplitPanel.Outer grow className="graph">
                <EuiSplitPanel.Inner>
                  <EuiAccordion
                    id="accordion1"
                    buttonContent={
                      <b>
                        #4 <br /> Biomedical Background knowledge
                      </b>
                    }
                  >
                    <EuiText>
                      <ol>
                        <li>
                          Clicking a drug or diagnosis from the bottom right
                          Medical History board. Double-click the square-shaped
                          purple node to expand the graph.
                        </li>
                        <li>
                          Clicking a diagnosis or drug related feature from the
                          top left Feature Importance board. Double click the
                          circle-shaped nodes to expand the graph; then
                          double-click the square-shaped purple nodes to expand
                          further. You can arrive here in two ways:
                        </li>
                      </ol>
                      <p>
                        Choose a preferred Graph Layout. Zoom with mouse wheel.
                        Arrange nodes with drag &amp; drop. Undo the last step
                        with the „undo“ bottom.
                      </p>
                    </EuiText>
                  </EuiAccordion>
                </EuiSplitPanel.Inner>
                <EuiSplitPanel.Inner>
                  <FeatureGraphContainer
                    patientID={patientID}
                    selectedFeature={selectedFeature}
                    selectedDrug={selectedDrug}
                    selectedDiag={selectedDiag}
                  />
                </EuiSplitPanel.Inner>
              </EuiSplitPanel.Outer>
              <EuiSplitPanel.Outer grow className="patient-history">
                <EuiSplitPanel.Inner>
                  <EuiAccordion
                    id="accordion1"
                    buttonContent={
                      <b>
                        #1 <br /> Medical History of Drugs and Diagnosis
                      </b>
                    }
                  >
                    <EuiText>
                      <ul>
                        <li>
                          The plot shows diagnosis (blue) and drug prescriptions
                          (orange) over time.
                        </li>
                        <li>You can zoom in and out with your mousewheel.</li>
                        <li>
                          Click on a diagnosis or drug to access the biomedical
                          background knowledge graph.
                        </li>
                        <li>
                          The x-axis shows the medical history (that why the
                          negative sign) in months prior to the index date
                          <span style={{ color: '#0070C0' }}>*</span>.
                        </li>
                      </ul>
                      <p>
                        <span style={{ color: '#0070C0' }}>*</span>The index
                        date is a patient-specific timepoint of an event, in in
                        this case the time point of first epilepsy diagnosis.
                        <br />
                        Everything before is defined a medical history.
                      </p>
                      <p>Patient demographics</p>
                    </EuiText>
                  </EuiAccordion>
                </EuiSplitPanel.Inner>
                <EuiSplitPanel.Inner>
                  <PatientHistoryCard
                    patientID={patientID}
                    selectDrug={selectDrug}
                    selectDiag={selectDiag}
                  />
                </EuiSplitPanel.Inner>
              </EuiSplitPanel.Outer>
            </GridDashboard>
          ) : (
            <WelcomeMessage />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </DashboardStyle>
  )
}

export default Dashboard
