import React from 'react'

import { Helmet } from 'react-helmet-async'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import featureImportance from './feature-importance.png'
import biomedBgKnowledge from './biomed-bg-knowledge.png'
import patientHistory from './patient-history.png'
import predictedRisks from './predicted-risks.png'

import { Header } from 'components/layout/Header'
import LayoutGrid, { Footer } from 'components/layout'

export function HelpPage() {
  return (
    <>
      <Helmet>
        <title>Upload Page</title>
        <meta name="description" content="Help" />
      </Helmet>
      <LayoutGrid>
        <Header />
        <EuiPageContent
          verticalPosition="center"
          horizontalPosition="center"
          className="w-75 w-md-50 my-3"
        >
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle>
                <h1>Help Page</h1>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiTitle size="s" className="mt-4">
              <h2>
                #1 <br />
                Medical History of Drugs and Diagnosis
              </h2>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiImage
                  size="m"
                  hasShadow
                  allowFullScreen
                  // caption="Click me"
                  alt="Medical History of Drugs and Diagnosis"
                  src={patientHistory}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText>
                  <ul>
                    <li>
                      The plot shows diagnosis (blue) and drug prescriptions
                      (orange) over time.
                    </li>
                    <li>You can zoom in and out with your mousewheel.</li>
                    <li>
                      Click on a diagnosis or drug to access the biomedical
                      background knowledge graph.
                    </li>
                    <li>
                      The x-axis shows the medical history (that why the
                      negative sign) in months prior to the index date
                      <span style={{ color: '#0070C0' }}>*</span>.
                    </li>
                  </ul>
                  <p>
                    <span style={{ color: '#0070C0' }}>*</span>The index date is
                    a patient-specific timepoint of an event, in in this case
                    the time point of first epilepsy diagnosis.
                    <br />
                    Everything before is defined a medical history.
                  </p>
                  <p>Patient demographics</p>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiTitle size="s" className="mt-4">
              <h2>
                #2 <br />
                Predicted time dependent risks for six different diseases
              </h2>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiImage
                  size="m"
                  hasShadow
                  allowFullScreen
                  // caption="Click me"
                  alt="Predicted time dependent risks for six different diseases"
                  src={predictedRisks}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText>
                  <p>
                    Hover your mouse cursor across for details. For more
                    interactions, like zooming, use the buttons appearing in the
                    top right corner of this dashboard.
                  </p>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiTitle size="s" className="mt-4">
              <h2>
                #3 <br />
                Feature Importance: Top-20 Risk Factors
              </h2>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiImage
                  size="m"
                  hasShadow
                  allowFullScreen
                  // caption="Click me"
                  alt="Feature Importance: Top-20 Risk Factors"
                  src={featureImportance}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText>
                  <ul>
                    <li>
                      <p>
                        Choose disease of interest by selecting panel 1 to 6.{' '}
                      </p>
                    </li>
                    <li>
                      <p>
                        The direction of the bars show the patient specific
                        importance of features estimated via Shapley Additive
                        Explanations (SHAP): Left indicates that the feature
                        attributes to risk reduction and right (positive) risk
                        increase.
                      </p>
                    </li>
                    <li>
                      <p>
                        The bar heights reflect the importance: The higher the
                        bar, the higher the contribution to the acutal predicted
                        risk.
                      </p>
                    </li>
                    <li>
                      <p>
                        Colors indicate the feature origin (demographic,
                        disease, diagnosis)
                      </p>
                    </li>
                    <li>
                      <p>
                        Click on a diagnosis or drug to access the associated
                        biomedical background knowledge graph.
                        <span style={{ color: '#0070C0' }}>*</span>
                      </p>
                    </li>
                  </ul>
                  <p>
                    <span style={{ color: '#0070C0' }}>*</span>In some cases
                    (specifically with simulated data) it can happen that no
                    biomedical background knowledge is available.
                  </p>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiTitle size="s" className="mt-4">
              <h2>
                #4 <br />
                Biomedical Background knowledge
              </h2>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiImage
                  size="m"
                  hasShadow
                  allowFullScreen
                  // caption="Click me"
                  alt="Biomedical Background knowledge"
                  src={biomedBgKnowledge}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText>
                  <ol>
                    <li>
                      Clicking a drug or diagnosis from the bottom right Medical
                      History board. Double-click the square-shaped purple node
                      to expand the graph.
                    </li>
                    <li>
                      Clicking a diagnosis or drug related feature from the top
                      left Feature Importance board. Double click the
                      circle-shaped nodes to expand the graph; then double-click
                      the square-shaped purple nodes to expand further. You can
                      arrive here in two ways:
                    </li>
                  </ol>
                  <p>
                    Choose a preferred Graph Layout. Zoom with mouse wheel.
                    Arrange nodes with drag &amp; drop. Undo the last step with
                    the „undo“ bottom.
                  </p>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPageContentBody>
        </EuiPageContent>
        <Footer />
      </LayoutGrid>
    </>
  )
}
