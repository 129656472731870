import React from 'react'
import LayoutGrid, { Footer } from 'components/layout'
import { Header } from '../../components/layout/Header'
import { Helmet } from 'react-helmet-async'
import {
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
} from '@elastic/eui'

import UploadComponent from 'components/UploadComponent'

export function UploadPage() {
  return (
    <>
      <Helmet>
        <title>Upload Page</title>
        <meta name="description" content="Upload" />
      </Helmet>
      <LayoutGrid>
        <Header />
        <EuiPageContent verticalPosition="center" horizontalPosition="center">
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle>
                <h2>Upload Data</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <UploadComponent />
          </EuiPageContentBody>
        </EuiPageContent>
        <Footer />
      </LayoutGrid>
    </>
  )
}
