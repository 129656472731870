import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// import styled from 'styled-components/macro'
import { ReactQueryConfigProvider } from 'react-query'

import { Helmet } from 'react-helmet-async'
import { DashboardPage } from './pages/DashboardPage'
import { UploadPage } from './pages/UploadPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { HelpPage } from './pages/HelpPage'
import { PublishingNotesPage } from './pages/PublishingNotesPage'
import { DataProtectionPage } from './pages/DataProtectionPage'
import { ImprintPage } from './pages/ImprintPage'
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage'

// const FixHeight = styled.div`
//   // 100hv – header - footer
//   min-height: calc(100vh - 49px - 90px);
// `

export const PatientContext = React.createContext({})

const queryConfig = {
  staleTime: 6000,
  retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  refetchOnWindowFocus: false,
}

const App = () => {
  const [patientID, setPatientID] = useState(null)
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [selectedDrug, selectDrug] = useState(null)
  const [selectedDiag, selectDiag] = useState(null)

  const store = {
    patient: { patientID, setPatientID },
    feature: { selectedFeature, setSelectedFeature },
    drug: { selectedDrug, selectDrug },
    diag: { selectedDiag, selectDiag },
  }

  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <PatientContext.Provider value={store}>
        <BrowserRouter>
          <Helmet
            titleTemplate="%s - Patient Demonstrator"
            defaultTitle="Patient Demonstrator"
          >
            <meta
              name="Risk-XAI"
              content="Explainable AI Models for Predicting Personalized Risk Profiles"
            />
          </Helmet>
          {/*<Header />*/}
          {/*<FixHeight>*/}
          <Switch>
            <Route exact path="/" component={DashboardPage} />
            <Route path="/upload" component={UploadPage} />
            <Route path="/help" component={HelpPage} />
            <Route path="/publishing-notes" component={PublishingNotesPage} />
            <Route path="/data-protection" component={DataProtectionPage} />
            <Route path="/imprint" component={ImprintPage} />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditionsPage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </PatientContext.Provider>
    </ReactQueryConfigProvider>
  )
}

export default App
