import React, { useEffect, useState } from 'react'
import 'react-table/react-table.css'
import Plot from 'react-plotly.js'

import { useMeasure } from 'react-use'
import { useQuery } from 'react-query'
import { getRiskProbDataByPatientID } from '../../api'

const RiskProbChart = ({ patientID }) => {
  const [cardRef, { width }] = useMeasure()
  const [processedData, setProcessedData] = useState()
  const { data } = useQuery(
    patientID && [`patient/${patientID}/risk-prob`, patientID],
    getRiskProbDataByPatientID,
  )

  useEffect(() => {
    if (data) {
      const comorbidities = data.map((value) => value.main_comorb)
      const uniqComorbidities = [...new Set(comorbidities)]

      setProcessedData(
        uniqComorbidities.map((comorbidity) => {
          let y = []
          let x = []
          data
            .filter((value) => value.main_comorb === comorbidity)
            .forEach((value) => {
              x.push(value.time)
              y.push(value.riskprob * 100)
            })
          return {
            x,
            y,
            type: 'scatter',
            name: comorbidity,
          }
        }),
      )
    }
  }, [data])

  return (
    <div
      ref={cardRef}
      // style={{ marginTop: '-20px' }}
    >
      <Plot
        data={processedData}
        layout={{
          title: 'Risk Probabilities',
          xaxis: { title: 'month relative to indexdate' },
          yaxis: { title: '% risk probability' },
          width,
        }}
        config={{ responsive: true }}
      />
    </div>
  )
}

export default RiskProbChart
