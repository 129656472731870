import axios from 'axios'

/* Big files for express server */

axios.defaults.baseURL = process.env.REACT_APP_BACKEND

/* ENROLS */

/**
 * Get patient list
 *
 * @returns {Promise<any>}
 */
export const getPatients = async () => {
  const { data } = await axios.get('api/v1/enrols/enrolids')
  return data
}

export const getSHAPValuesDataByPatientID = async (key, patientID) => {
  const { data } = await axios.get(`api/v1/enrols/${patientID}/shap_prediction`)
  return data
}

export const getRiskProbDataByPatientID = async (key, patientID) => {
  const { data } = await axios.get(`api/v1/enrols/${patientID}/risk_prediction`)
  return data
}

export const getStaticHistoryByPatientID = async (key, patientID) => {
  const { data } = await axios.get(`api/v1/enrols/${patientID}/static_history`)
  return data
}

export const getDiagnosisHistoryByPatientID = async (key, patientID) => {
  const { data } = await axios.get(`api/v1/enrols/${patientID}/diags_history`)
  return data
}

export const getDrugsHistoryByPatientID = async (key, patientID) => {
  const { data } = await axios.get(`api/v1/enrols/${patientID}/drugs_history`)
  return data
}

/* GRAPH */

export const getGraphByShapDrugFeature = (key, drugFeature) => {
  const response = axios.get(`api/v1/graph/drug/${drugFeature}`)
  const { data } = response
  return data
}

export const getGraphByShapDiagFeature = (key, diagFeature) => {
  const response = axios.get(`api/v1/graph/diag/${diagFeature}`)
  const { data } = response
  return data
}

export const getGraphByShapFeature = (
  key,
  domain,
  subdomain,
  feature_pruned,
  patientID,
) => {
  if (subdomain)
    return axios.get(
      `/api/v1/graph/shap/enrols/${patientID}/${domain}/${subdomain}/${feature_pruned}`,
    )
  else
    return axios.get(
      `/api/v1/graph/shap/enrols/${patientID}/${domain}/${feature_pruned}`,
    )
}

export const getGraphByDrug = (key, drug) => {
  return axios.get(`api/v1/graph/drug/${drug}`)
}
export const getGraphByDiag = (key, diag) => {
  return axios.get(`api/v1/graph/diag/${diag}`)
}

export const getGraphByDrugdiagSubdomain = (key, node, nodeType, subdomain) => {
  return axios.get(`api/v1/graph/${nodeType}/${node}/${subdomain}`)
}

export const expandGraphByNode = (key, node, nodeType, clickedNode) => {
  const { nodeData, edgeData } = clickedNode
  if (nodeData.type === 'value') return null
  if (nodeData.type === 'drug')
    return axios.get(`api/v1/graph/drug/${nodeData.label}`)
  if (nodeData.type === 'diag')
    return axios.get(`api/v1/graph/diag/${nodeData.label}`)

  console.log(nodeData, edgeData)
  const [domain, subdomain] = nodeData.subdomain.split('.')
  console.log(domain, subdomain)
  if (nodeData.type === 'subdomain') {
    // TODO: implement multiple requests, if there are many edges
    let sourceID, sourceType
    if (domain === 'DRUG') {
      sourceID = nodeData.drug
      sourceType = 'drug'
    } else if (domain === 'DISEASE') {
      sourceID = nodeData.diag
      sourceType = 'diag'
    }
    return axios.get(
      `api/v1/graph/${sourceType}/${sourceID}/${nodeData.subdomain}`,
    )
  }
  return axios.get(`api/v1/graph/${nodeType}/${node}/${nodeData.label}`)
}
/**
 * Fetch detailed feature information
 *
 * Note: not for react-query: key is missing
 *
 * @param feature
 * @returns {Promise<any>}
 */
export const getFeatureInfo = async (feature) => {
  const { data } = await axios.get(`api/v1/feature/${feature}`)
  return data
}
/**
 * Fetch a set of features
 *
 * Suited for react-query
 *
 * @param key
 * @param featureSet
 * @returns {Promise<any>}
 */
export const getFeatureSetInfo = async (key, featureSet) => {
  const { data } = await axios.post('api/v1/features', { featureSet })
  return data
}
