/**
 *
 * PatientStaticHistory
 *
 */
import React from 'react'

import { Descriptions } from 'antd'
import isEmpty from 'lodash/isEmpty'

import { useQuery } from 'react-query'

import { getStaticHistoryByPatientID } from '../../api'

export type PatientStaticHistoryProps = {
  patientID: string
}

export const PatientStaticHistory = ({
  patientID,
}: PatientStaticHistoryProps) => {
  const { data: staticHistoryData }: any = useQuery(
    // @ts-ignore
    patientID && [`patient/${patientID}/static_history`, patientID],
    getStaticHistoryByPatientID,
  )

  if (staticHistoryData && !isEmpty(staticHistoryData)) {
    return (
      <Descriptions title="Patient Info" className="mt-3">
        <Descriptions.Item label="Enrolment ID">
          {staticHistoryData.enrolid}
        </Descriptions.Item>
        <Descriptions.Item label="Age">
          {staticHistoryData.age}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">
          {staticHistoryData.sexc}
        </Descriptions.Item>
        <Descriptions.Item label="Region">
          {staticHistoryData.regionc}
        </Descriptions.Item>
        <Descriptions.Item label="Days in Hospital">
          {staticHistoryData.hospdays}
        </Descriptions.Item>
        <Descriptions.Item label="Coverage of prescriptions">
          {staticHistoryData.coverage_of_prescriptions}
        </Descriptions.Item>
        <Descriptions.Item label="Insurance Health Plan">
          {staticHistoryData.hlthplnc}
        </Descriptions.Item>
        <Descriptions.Item label="Insurance Plantype">
          {staticHistoryData.plantypc}
        </Descriptions.Item>
        <Descriptions.Item label="Major Metropolitan Area">
          {staticHistoryData.mma}
        </Descriptions.Item>
      </Descriptions>
    )
  }
  return null
}
