import React, { FC } from 'react'
import {
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiText,
  EuiTitle,
  EuiLink,
} from '@elastic/eui'

export type WelcomeMessageProps = {}

export const WelcomeMessage: FC<WelcomeMessageProps> = (props) => {
  return (
    <EuiPageContent
      verticalPosition="center"
      horizontalPosition="center"
      className="w-50 my-3"
    >
      <EuiPageContentHeader>
        <EuiPageContentHeaderSection>
          <EuiTitle>
            <h2>
              Welcome to Risk-XAI: Explainable AI Models for Predicting
              Personalized Risk Profiles
            </h2>
          </EuiTitle>
        </EuiPageContentHeaderSection>
      </EuiPageContentHeader>
      <EuiPageContentBody>
        <EuiText>
          <p>
            The motivation for this demo-tool is to show, how complex AI models
            can be made explainable by:
          </p>
          <ol type="a">
            <li>
              Disentangling most predictive features for an individual patient
            </li>
            <li>Mapping of features on available background knowledge</li>
          </ol>
          <p>Using this demonstrator you can:</p>
          <ul>
            <li>
              browse through the medical history of a patient and map diagnoses
              and medications on available background knowledge,{' '}
            </li>
            <li>
              make individualized predictions of future risks for a set of
              comorbidities that are common for epilepsy patients,
            </li>
            <li>
              explore most predictive factors for each individual and map them
              on available background knowledge about diagnoses and medications.
            </li>
          </ul>
          <p>
            Personal risk profiles are estimated via DeepLORI, an AI based
            algorithm recently developed by the AI &amp; Data Science team at
            SCAI (
            <EuiLink
              href="https://www.frontiersin.org/articles/10.3389/frai.2021.610197/full"
              target="_blank"
              rel="noreferrer"
            >
              https://www.frontiersin.org/articles/10.3389/frai.2021.610197/full
            </EuiLink>
            ). The model has been trained with data of more than 20,000 epilepsy
            patients observed over a time period of 1 year. It has been
            validated with additional data of almost 100,000 epilepsy patients.
          </p>
          <p>
            <u>Quick Start - Playground using simulated patients:</u>
            <br />
            Real patient data is highly sensitive and cannot be made public due
            to legal constraints. Hence, we cannot show data or predictions for
            real patients here. We thus allow for exploring the potential of our
            algorithms via the help of simulated patients.{' '}
          </p>
          <ol>
            <li>
              From the top-left dropdown-menu select an arbitrary patient you
              like to play with. These are simulated patients, not real
              patients.
            </li>
            <li>The dashboards descriptions will give you guidance.</li>
          </ol>
          <p>
            <u>Quick Start – Get predictions for your own data:</u>
            <br />
            Please note our Disclaimer below. You can upload your own data to
            this tool and receive predictions using our AI models. The required
            data format can be retrieved via the "Download Data" button in the
            top right corner in the dashboard view. The process is as follows:
          </p>
          <ol>
            <li>
              Upload Data: We recommend to first download the simulated data to
              understand the required data format.
            </li>
            <li>
              Wait 5-10 minutes for finishing the necessary calculations.
              <span style={{ color: '#0070C0' }}>*</span>
            </li>
            <li>
              After the computations are finished, the dashboards will show up.
              You can start interacting with the results now.
              <span style={{ color: '#0070C0' }}>*</span>
            </li>
          </ol>
          <p>
            Note: There is absolutely NO uploaded data stored at our side, it is
            only buffered for allowing to perform the necessary calculations.
            <span style={{ color: '#0070C0' }}>*</span> If you like to save the
            outcome, you could make screenshots.
          </p>
          <p>
            <span style={{ color: '#0070C0' }}>*</span>Refreshing the page,
            moving to another URL or closing the browser will delete all
            progress and data.
          </p>
        </EuiText>
      </EuiPageContentBody>
    </EuiPageContent>
  )
}
